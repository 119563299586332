import Swiper, {Pagination, Autoplay, Navigation} from "swiper";

export class Slider {
  constructor() {

    document.querySelectorAll('*[slider]').forEach(slider => {

      this.registerSlider(slider)

    })
  }

  registerSlider(slider) {

    let name = slider.getAttribute('data-slider')
    let per_view = slider.getAttribute('per-view')
    let per_view_sm = slider.getAttribute('per-view-sm')
    let nav = slider.getAttribute('navigation')
    let space = slider.getAttribute('space')
    let autoplayAttr = slider.getAttribute('autoplayAttr')

    const modules = [Pagination, Autoplay]
    let navigation = {}
    let pagination = {}
    let autoplay = false

    if (nav.includes('pagination')) {
      pagination = {
        el: '.swiper-pagination',
        clickable: true
      }
      modules.push(Pagination)
    }

    if (nav.includes('navigation')) {
      navigation = {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
      modules.push(Navigation)
    }

    if (autoplayAttr === 'true') {
      autoplay = {
        delay: 4000,
        disableOnInteraction: true
      }
      modules.push(Autoplay)
    }


    const
      swiper = new Swiper('.' + name, {
        modules: modules,
        slidesPerView: per_view_sm ?? per_view,
        navigation: navigation,
        pagination: pagination,
        autoplay: autoplay,
        loop: false,
        spaceBetween: space ?? 0,
        breakpoints: {
          1025: {
            slidesPerView: per_view
          }
        }
      });
  }
}
