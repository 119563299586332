import '@css/main.scss';
import '@js/bootstrap';

import {Header} from '@js/components/Header';
import {Slider} from '@js/components/Slider';
import {LineFollow} from "@js/utils/LineFollow";
import { Discovery } from './components/Discovery';
import {Archive} from "@js/utils/Archive";
import {Scroll} from "@js/utils/Scroll";

window.onload = () => {

  new Header()
  new Slider()
  new LineFollow()
  new Discovery()
  new Archive()
  new Scroll()

}
