export class LineFollow {
  constructor() {
    this.config()
  }

  config() {
    let line = document.querySelector('#lineFollow')
    if (!line) return
    let path = line.querySelector('path')

    document.addEventListener('scroll', function () {
      let scrollValue = window.scrollY;

      path.style.strokeDashoffset = scrollValue / 5;
    })
  }
}
