export class Discovery {
  constructor() {
    this.init();
  }

  init() {
    let el = document.querySelector(".c-discovery");
    if (!el) return;

    let items = el.querySelectorAll(".c-discovery-item");

    items.forEach((item) => {
      item.addEventListener("mouseover", (item) => {
        items.forEach((item) => {
          item.classList.remove("active");
        });

        item.target.closest(".c-discovery-item").classList.add("active");
      });
    });
  }
}
