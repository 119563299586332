export class Archive {
  constructor() {

    this.container = document.querySelector('*[data-archive]')
    if (!this.container) {
      return;
    }

    this.type = this.container.getAttribute('data-archive')
    this.display = this.container.getAttribute('data-format')
    this.targetObserver = this.container.querySelector('#js-observer')
    this.itemsContainer = this.container.querySelector('*[data-archive-endpoint]')
    this.loader = this.container.querySelector('.loader')
    this.itemsperpage = this.container.getAttribute('data-itemsperpages')

    this.init()
  }

  init() {
    this.config = this.defaults()
    this.url = `${window.location.protocol}//${window.location.host}/wp-json/akyos/v1/${this.type}`

    this.register()
  }

  defaults() {
    return {
      noMoreItems: false, currentPage: 1,
    }
  }

  reset() {
    this.config = this.defaults()
    this.itemsContainer.innerHTML = "";
  }

  register() {
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.config.currentPage++

          if (this.config.noMoreItems) {
            observer.unobserve(this.targetObserver)
          }

          this.fetch();
        }
      })
    }

    this.observer = new IntersectionObserver(callback, {
      root: null, rootMargin: '0px', threshold: 0.1
    })


    this.observer.observe(this.targetObserver)
  }

  fetch() {
    const params = new URLSearchParams();
    params.append('page', this.config.currentPage)
    params.append('per_page', this.itemsperpage)

    this.loader.removeAttribute('hidden')

    fetch(`${this.url}?${params.toString()}`)
      .then((r) => r.json())
      .then(r => {

        if (r.length === 0) {
          this.config.noMoreItems = true;
          console.log('no more items')
        } else {
          this.appendItems(r);
          console.log('append items')
        }

        this.loader.setAttribute('hidden', 'hidden')

      })
      .catch(r => {
        this.config.noMoreItems = true;
      })
  }


  appendItems(items) {
    const template = document.querySelector('#archive-el');

    items.forEach((item) => {
      let clone = template.content.cloneNode(true);
      clone = this.itemPost(clone, item)
      this.itemsContainer.appendChild(clone)

    })
  }


  itemPost(clone, item) {
    if (this.display === 'inline-grid') {

      const image = clone.querySelector('.c-postcard-image')
      const cat = clone.querySelector('.c-postcard-content__cat')
      const title = clone.querySelector('.c-postcard-content .c-title')
      const date = clone.querySelector('.c-postcard-content__date')

      let img = document.createElement('img');
      img.setAttribute('src', item.thumbnail);

      if (item.thumbnail) {
        image.appendChild(img);
      }

      title.innerHTML = item.title;
      date.innerHTML = item.date;
      cat.innerHTML = item.cat;

    } else {
      const image = clone.querySelector('.c-post-image__img');
      const cat = clone.querySelector('.c-post-image__cat');
      const title = clone.querySelector('.c-post-content-header .c-title');
      const address = clone.querySelector('.c-post-content-subheader__address');
      const phone = clone.querySelector('.c-post-content-subheader__phone');
      const description = clone.querySelector('.c-post-content-body');
      const stars = clone.querySelector('.c-post-content-header__stars');

      switch (this.type) {
        case 'aky_hosting':
          const equipements = clone.querySelector('.c-post-content-footer__list.equipments ul');
          const services = clone.querySelector('.c-post-content-footer__list.services ul');

          if (item.equipments) {
            item.equipments.forEach((equipment) => {
              let li = document.createElement('li');
              li.innerHTML = '-' + equipment.value;
              equipements.appendChild(li)
            })
          }

          if (item.services) {
            item.services.forEach((service) => {
              let li = document.createElement('li');
              li.innerHTML = '-' + service.value;
              services.appendChild(li)
            })
          }
          break;
        case 'aky_restaurant':
          const nbcouverts = clone.querySelector('*[data-couvert] .title span');
          const nbsalles = clone.querySelector('*[data-salle] .title span');
          const animations = clone.querySelector('*[data-animation] .content');

          nbcouverts.innerHTML = item.nbCouvert;
          nbsalles.innerHTML = item.nbSalle;
          animations.innerHTML = item.animations;

          break;
      }

      let img = document.createElement('img');
      img.setAttribute('src', item.image);
      img.setAttribute('alt', item.title);

      if (item.image) {
        image.appendChild(img);
      }

      title.innerHTML = item.title;
      address.innerHTML = item.address;
      cat.innerHTML = item.cat;
      phone.innerHTML = item.phone;
      phone.setAttribute('href', `tel:+33${item.phone}`);
      description.innerHTML = item.description;

      let svg = clone.querySelector('.js-data-svg svg')

      for (let i = 0; i < item.stars; i++) {
        stars.appendChild(svg.cloneNode(true))
      }
    }

    return clone
  }
}
